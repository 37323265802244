<template>
  <router-view></router-view>
</template>

<script>
export default {
  page: {
    title: 'Assignment',
    meta: [
      {
        name: 'description',
      },
    ],
  },
}
</script>
